.accordion {
  max-width: 1140px;
  margin: auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  background-color: #f1f1f1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  align-items: baseline;
}
.accordion-title:hover,
.accordion-title:hover span {
  background-color: #0e5472;
  color: #fff;
}
.accordion-title  .open-close-icon {
  font-size: 22px;
}
.accordion-title,
.accordion-content {
  padding: 1rem;
}
.accordion-title.open {
  padding: 1rem;
  background-color: #0e5472;
  color: #fff;
  align-items: baseline;
}

.accordion-content {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #eee;
  padding: 15px;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.7;
}
.accordion-item {
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}
.accordion-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* .accordion-title.close {
  background-color: #f8f8f8;
  opacity: 1;
} */
/* .close:not(:disabled):not(.disabled){
    background-color: #f8f8f8;
    opacity: 1;
  } */

@font-face {
  font-family: 'JameelNoori';
  src: url('./assets/fonts/Jameel-Noori/Jameel-Noori-Nastaleeq-Regular.ttf') format('truetype');
}

.loading-tr {
  height: 100px;
}
.loading-table-wrapper {
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
}
.flex-large {
  position: relative;
}
/* //pageination */
.pagination.user-pag{
  justify-content: center;
  margin: 20px 0;
}
.pagination.user-pag .pagination-wrapper{
  gap: 20px;
}
.pagination.user-pag .pagination-wrapper a{
  height: 40px;
  width: 40px;
}
.pagination{
  display: flex;
  justify-content: flex-end;
}
.pagination .pagination-wrapper{
  display: flex;
  gap: 10px;
}
.pagination .pagination-wrapper a{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-family: 'Poppins';
  border: 1px solid #0e5472;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.pagination .pagination-wrapper a.active{
  background-color: #0e5472;
  color: white;
}

.font-urdu{
  font-family: 'JameelNoori', sans-serif !important;
}
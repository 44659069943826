.table-div {
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.45);
  margin: 0px 0px 15px 0px;
}
.button-div {
  display: flex;
  gap: 10px;
}
.button-div button {
  min-width: 90px;
  text-align: center;
}
.model-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.table-content {
  gap: 10px;
}

.select-option {
  padding: 10px 5px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.btn-green-gradient {
  background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
  color: white;
  font-weight: 800;
}
.modal-backdrop {
  display: none;
}

/* changes */
.title-video-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}
.modal {
  height: calc(100vh - 60px);
  margin-top: 60px;
}
.video-upload-dialog .dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.video-upload-dialog select {
  display: block;
  width: 100%;
}
.error-text {
  color: red;
}

.approved {
  color: green;
}
.pending {
  color: orange;
}
#alert-dialog-salaqeen .form-check {
  padding-left: 0;
}
#alert-dialog-salaqeen .form-switch .form-check-input {
  margin-left: 1.5rem;
  font-size: 20px;
}
@media (max-width: 991px) {
  .title-video-upload h1 {
    font-size: 20px;
  }
}
.cnfrm-btn-wrap {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding-bottom: 20px;
}
.floor-map {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.controls {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.floor-map .submit-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.controls .form-group-control {
  width: 100%;
  display: flex;
  gap: 20px;
}
.controls .form-group .MuiTextField-root {
  width: 100%;
}
.controls .form-group.w50 {
  width: 50%;
}
.controls .form-group.w100 {
  width: 100%;
}
.controls label {
  display: block;
  font-weight: bold;
}
.controls .MuiOutlinedInput-root {
  background-color: white;
}

/* .controls input[type="text"],
  .controls input[type="number"] {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  } */

.controls .generate-floor {
  padding: 5px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}
.controls .generate-floor:hover {
  color: #007bff;
}

.floor {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 25px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.floor h2 {
  margin: 0 auto;
}

.row {
  display: flex;
  /* gap: 10px; */
  /* align-items: center; */
}
.grand-islamic-card .image {
  padding: 0;
}

.seat {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.seat.available {
  background-color: #007bff;
  color: #fff;
  position: relative;
}

.seat.available::before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}

.seat.booked {
  background-color: #f8f8f8;
  color: #ccc;
  cursor: not-allowed;
}

.seat:hover {
  opacity: 0.8;
}

.delete-column {
  display: flex;
  width: 30px;
  height: 30px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  background-color: #17034a;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
  justify-content: center;
  align-items: center;
}

.delete-seat {
  border-radius: 50% !important;
  min-width: 45px !important;
  min-height: 45px !important;
}
.delete-column:hover {
  background-color: white;
  color: #17034a;
  border: solid;
}
/* ============================ */
.view-floor-wrapper {
  text-align: center;
  position: relative;
}
.view-floor-wrapper > .btn {
  position: absolute;
  top: 5px;
  right: 10px;
  letter-spacing: 0.4px; 
}
.view-floor-wrapper .floor-wrapper {
  margin-bottom: 30px;
}
.view-floor-wrapper .floor-wrapper h2 {
  margin: 0 auto 30px;
  position: relative;
  text-align: center;
  max-width: 350px;
  font-weight: 600;
  color: #b07519;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  padding: 10px;
}
.view-floor-wrapper .floor-wrapper h2::after {
  position: absolute;
  content: "";
  width: 100%;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  background: #b07519;
}
.view-floor-wrapper .floor-wrapper h2::before {
  position: absolute;
  content: "";
  width: 80%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  background: #b07519;
}
.view-floor-wrapper .floor-wrapper h2 span {
  font-weight: 600;
  font-family: monospace;
}
.view-floor-wrapper p {
  font-size: 24px;
}
.view-floor-wrapper h2 {
  margin-bottom: 30px;
}
.view-floor-wrapper h2 span {
  font-weight: 600;
  font-family: sans-serif;
  text-transform: capitalize;
  color: #0e5472;
}
.view-floor-wrapper h2 span.city {
  font-style: italic;
  font-size: 20px;
  text-transform: capitalize;
  color: #46798f;
}
.view-floor-wrapper .seat-icon {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}
.view-floor-wrapper .seat-icon svg {
  min-width: 45px;
}
.view-floor-wrapper .seat-view {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  position: relative;
  color: white;
  min-width: 45px;
  text-align: center;
}
.view-floor-wrapper .seat-view-wrapper {
  width: 100%;
  display: flex;
  overflow: auto;
  padding: 10px 0;
  margin-bottom: 26px;
}
.view-floor-wrapper .seat-view-wrapper::-webkit-scrollbar {
  height: 12px;
  width: 10px;
}
.view-floor-wrapper .seat-view-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}
.view-floor-wrapper .seat-view-wrapper::-webkit-scrollbar-thumb {
  background: #0e5472;
  border-radius: 4px;
}
.view-floor-wrapper .seat-view-wrapper > .seat-icon:first-child {
  margin-left: auto;
}

.view-floor-wrapper .seat-view-wrapper > .seat-icon:last-child {
  margin-right: auto;
}
.view-floor-wrapper .seat-view-wrapper .seat-icon .seat-icon-info {
  border: 1px solid #80808075;
  padding: 8px 8px 5px;
  background-color: #dff5be;
}
.view-floor-wrapper .seat-view-wrapper .seat-icon .row-name {
  padding-top: 3px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
}
.view-floor-wrapper .seat-view-wrapper .seat-icon-info svg {
  width: 50px;
  height: 70px;
  background-color: #fff;
}
.view-floor-wrapper .seat-view-wrapper .seat-icon-info.active svg {
  background-color: #00cd00;
}
.view-floor-wrapper .seat-view-wrapper .seat-icon-info.selected svg {
  background-color: #7a8d7a;
}
.view-floor-wrapper .seat-view::before {
  content: "";
  position: absolute;
  width: 75%;
  height: 75%;
  top: 1px;
  left: 50%;
  transform: translate(-50%, 0%);
  background: rgba(255, 255, 255, 0.4);
  border-radius: 3px;
}
.floor .seat-view-wrapper {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.floor .seat-view-wrapper > div {
  display: flex;
}
/* .fixed-sidebar .app-main .app-main__outer {
  width: calc(100% - 300px);
} */

.floor .seat-icon-info {
  color: black;
  margin: 0;
  font-size: 13px;
  line-height: 15px;
  padding-top: 2px;
  text-align: center;
  font-weight: 600;
}
.floor .seat-icon {
  position: relative;
  cursor: pointer;
  margin: 10px;
  transition: transform 0.2s;
}
.floor .seat-icon:hover {
  transform: scale(1.3);
}
.book-selected-seat {
  background-color: #0e5472 !important;
}

.floor-map .form-group-control.fields {
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 0;
}
.form-group-control.fields .w50 {
  width: calc(50% - 10px);
}
.form-group-control.fields .select > label {
  margin: 0;
}
.floor {
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
}

.floor .seat-icon svg {
  width: 40px;
  min-width: auto;
  height: auto;
  background-color: #fff;
}
.floor .seat-icon {
  text-orientation: unset;
  border: 1px solid #80808075;
  padding: 8px 8px 5px;
  background-color: #dff5be;
  margin-right: 8px;
}
.floor .floor-wrap {
  width: 100%;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.floor .floor-wrap .seat-view-wrapper .wrap {
  margin-left: auto;
}

.floor .floor-wrap .seat-view-wrapper .delete-seat {
  margin-right: auto;
}
.floor .floor-wrap::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
.floor .floor-wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}
.floor .floor-wrap::-webkit-scrollbar-thumb {
  background: #0e5472;
  border-radius: 4px;
}
.itikaaf-wrap {
  position: relative;
}
.itikaaf-wrap .seat-view-wrapper {
  padding: 10px 0;
  margin-bottom: 26px;
}
.itikaaf-wrap .btn {
  position: absolute;
  top: 5px;
  right: 5px;
  letter-spacing: 0.4px;
}
.itikaaf-wrap .seat-view-wrapper .seat-icon {
  margin: 0;
  margin-right: 10px;
}

.itikaaf-wrap .seat-view-wrapper > .seat-icon:first-child {
  margin-left: auto;
}

.itikaaf-wrap .seat-view-wrapper > .seat-icon:last-child {
  margin-right: auto;
}

.itikaaf-wrap .seat-view-wrapper .seat-icon .seat-icon-info {
  margin: 0;
  position: unset;
  transform: unset;
  writing-mode: unset;
  text-orientation: unset;
  border: 1px solid #80808075;
  padding: 8px 8px 5px;
  background-color: #dff5be;
}

.itikaaf-wrap .seat-view-wrapper .seat-icon .row-name {
  padding-top: 3px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
}

.itikaaf-wrap .seat-view-wrapper .seat-icon-info svg {
  width: 50px;
  height: 70px;
  background-color: #fff;
}
.itikaaf-wrap .seat-view-wrapper .seat-icon-info.active svg {
  background-color: #00cd00;
}
.itikaaf-wrap .seat-view-wrapper .seat-icon-info.selected svg {
  background-color: #7a8d7a;
}
.error-msg {
  color: red;
  margin-top: 5px;
}
.error-msg.floor-msg {
  text-align: center;
}

.back-btn-wrap {
  display: flex;
  padding-top: 4px;
}
.back-btn {
  gap: 7px;
  align-items: center;
  justify-content: center;
}
.frm-contr {
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 0;
}
.main-container {
  font-family: 'El Messiri', Sans-serif;
}

/* -----navbar------ */
.main-container .navbar {
  padding: 0;
  background-color: #0e5472 !important;
}
.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}
.main-container .navbar .navbar-collapse {
  justify-content: space-between;
}
.main-container .navbar .navbar-collapse .logo {
  width: 80px;
  height: auto;
  margin-left: 16px;
  cursor: pointer;
}
.main-container .navbar .nav-item .nav-link {
  color: #fff;
  font-family: 'El Messiri', Sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 28px 20px;
}
.main-container .navbar .nav-item {
  border-top: 4px solid transparent;
}
.main-container .navbar .nav-item:hover {
  border-top: 4px solid #fec468;
}
.main-container .navbar .nav-item:hover .nav-link {
  color: #fec468;
}

.main-container .navbar .nav-item.active .nav-link {
  color: #fec468;
}

.main-container .navbar .navbar-collapse .nav-item.dropdown:hover .dropdown-menu {
  display: block;
  padding: 0;
  border-radius: 0;
}
.main-container .navbar .navbar-collapse .nav-item.dropdown:hover .dropdown-menu .dropdown-item {
  padding: 8px 16px;
  color: #fff;
}
.main-container .navbar .navbar-collapse .nav-item.dropdown .dropdown-menu {
  background-color: #0e5472;
}

.main-container .navbar .navbar-collapse .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #fff;
  color: #000;
}

@media (min-width: 768px) {
  .main-container .navbar-expand-md .navbar-collapse {
    justify-content: center;
  }
}

/* ------sidebar----- */

/* Sidebar */
.navbar-container #sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0e5472;
  padding: 64px 10px;
  transform: translateX(0%);
  transition: transform 0.3s ease;
  z-index: 50;
  overflow: scroll;
}
.main-container .navbar .navbar-toggler {
  color: #fff;
  z-index: 51;
  padding-right: 0;
}
.navbar-container #sidebar.hide {
  transform: translateX(-100%);
}

/* Sidebar Menu */
.navbar-container .menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-container .menu li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  color: #fff;
}

.navbar-container .sidebar-menu-li.active {
  background-color: #ffb948;
}
.navbar-container .sidebar-menu-li.active {
  background-color: #fff;
}
.navbar-container .menu li:last-child {
  border: none;
}
.navbar-container .sidebar-menu-li.active a {
  color: #f7ecd0;
}
.navbar-container .sidebar-menu-li.active a {
  color: #000;
}
.navbar-container .menu li a {
  text-decoration: none;
  color: #fff;
  width: 100%;
  display: block;
}

.navbar-container .menu li:hover a {
  color: #f7ecd0;
}

/* Content */
.navbar-container #content {
  margin-left: 250px;
  padding: 10px;
}

/* Neumorphic Effect */

.navbar-container #sidebar.hide,
.navbar-container .neumorphic.hide {
  box-shadow: unset;
}

/* Toggle Button */
.navbar-container #toggle {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background-color: #f4f4f4;
  border-radius: 50%;
  box-shadow: 8px 8px 20px #c3c3c3, -8px -8px 20px #ffffff;
  cursor: pointer;
  z-index: 9999;
}

.navbar-container #toggle span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 20px;
  height: 2px;
  background-color: #333;
  transition: all 0.3s ease;
}

.navbar-container #toggle.hide span:first-child {
  transform: translate(-50%, -50%) rotate(45deg);
}

.navbar-container #toggle.hide span:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.navbar-container #toggle.hide span:nth-child(2) {
  transform: translate(-50%, -50%) scale(0);
}

/* --------------------- */

.main-container .banner-container .banner-img img {
  height: auto;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.main-container .banner-container.home-banner {
  background-image: url('../../../assets/images/star-bg.png');
}
.main-container .banner-container .banner-slider img {
  height: auto;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.main-container .about-container .row {
  margin-left: 0px;
  margin-right: 0px;
  max-width: 1140px;
  margin: auto;
}

.main-container .about-container .about-heading-title {
  color: #b07519;
  font-family: 'El Messiri', Sans-serif;
  font-size: 38px;
  position: relative;
  /* font-weight: 600; */
}

.main-container .about-container .about-heading-title-divider {
  border-style: solid;
  color: #b07519;
  border-width: 2px;
  width: 33%;
  margin: 0 auto;
  margin-left: 0;
}

.main-container .about-container {
  padding: 124px 0;
  background-image: url('../../../assets/images/texture.png');
  background-size: 16%;
}

.main-container .about-container .about-heading-content {
  margin: 0px 0 40px 0;
}

.main-container .about-container .about-heading-content p {
  color: #535353;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.main-container .about-container .about-action {
  font-family: 'El Messiri', Sans-serif;
  font-size: 18px;
  font-weight: 400;
  background-color: #0e5472;
  padding: 15px 30px;
  border-radius: 4px;
  width: 190px;
}

.main-container .about-container .about-action .button-link {
  text-decoration: none;
}

.main-container .about-container .about-action .button-link .button-content-wrapper {
  display: flex;
  gap: 20px;
  color: white;
}

.main-container .about-container .about-right-img img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.main-container .about-container .about-left .title {
  padding: 40px 0 45px;
}
.main-container .about-container .about-left .title .about-heading-title::before {
  content: '';
  position: absolute;
  bottom: -20px;
  width: 35%;
  height: 5px;
  background-color: #b07519;
}
.main-container .upcoming-container {
  background-image: url('../../../assets/images/blueish.jpg');
  background-position: center center;
  background-size: cover;
}
.main-container .upcoming-container .upcoming- {
  position: relative;
}
.main-container .upcoming-container .upcoming-::after {
  content: '';
  position: absolute;
  bottom: -28px;
  width: 100%;
  height: 28px;
}
.main-container .upcoming-container .upcoming-overlay {
  background-color: #000000;
  opacity: 0.7;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.main-container .upcoming-container .row {
  margin-left: 0px;
  margin-right: 0px;
  max-width: 1140px;
  margin: auto;
}

.main-container .upcoming- {
  background-color: #0000009e;
}

.main-container .upcoming-container .upcoming-left {
  padding: 80px 0 70px;
}

.main-container .upcoming-container .upcoming-left-iframe {
  height: 287px;
}

.main-container .upcoming-container .upcoming-left-iframe iframe {
  height: 100%;
  width: 100%;
  border: none;
  background-color: #000;
}

.main-container .upcoming-container .upcoming-heading-title {
  color: #ffffff;
  font-family: 'El Messiri', Sans-serif;
  font-size: 24px;
  /* font-weight: 600; */
  margin: 20px 0;
  text-align: center;
}

.main-container .upcoming-container .upcoming-content p {
  color: #ffffff;
  font-family: 'Poppins', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;
}

.main-container .upcoming-container .button-link {
  font-family: 'Montserrat', Sans-serif;
  font-weight: 500;
  background-color: #d8b600;
  font-size: 18px;
  padding: 20px 40px;
  border-radius: 5px;
  width: auto;
  box-shadow: none;
  text-decoration: none;
  display: inline-block;
  color: #fff;
  margin: 20px 0 10px 0;
}

.main-container .upcoming-container .button-link:hover {
  color: #fff;
  background-color: #000;
}

.main-container .upcoming-container .button-content-wrapper {
  display: flex;
  gap: 20px;
}

.main-container .upcoming-container .upcoming-action {
  display: flex;
  justify-content: center;
}

.main-container .upcoming-container .upcoming-right {
  padding: 80px 0 70px;
}

.main-container .upcoming-container .upcoming-right-img {
  display: flex;
  justify-content: center;
}
.main-container .upcoming-container .upcoming-right-img .video {
  position: relative;
}
.main-container .upcoming-container .upcoming-right-img .video .audio-file {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}
.main-container .upcoming-container .upcoming-right-img .video .audio-file audio {
  width: 100%;
}

.main-container .upcoming-container .upcoming-right-img img {
  height: 287px;
  /* height: auto; */
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.main-container .quote-container {
  background-image: url('../../../assets/images/texture.png');
  background-size: 16%;
  padding: 100px 0 50px;
}

.main-container .quote-container .quote-heading-title {
  color: #b07519;
  font-family: 'El Messiri', Sans-serif;
  font-size: 38px;
  position: relative;
}
.main-container .quote-container .quote-heading-title::after {
  content: '';
  position: absolute;
  width: 40%;
  height: 5px;
  background: #b07519;
  left: 0;
  bottom: -20px;
}
.main-container .quote-container .quote-heading-title-divider {
  border-style: solid;
  color: #b07519;
  border-width: 2px;
  width: 175px;
  margin: 0 auto;
  font-family: 'El Messiri', Sans-serif;
}

.main-container .quote-container .quote-row {
  max-width: 1140px;
  margin: auto;
  align-items: center;
}
.main-container .quote-container .quote-row .title {
  padding: 55px 0;
}
.main-container .quote-container .quote-row .description > h2 {
  color: #535353;
  font-family: 'Poppins', Sans-serif;
  font-size: 25px;
  font-weight: 400;
}
.main-container .recent-videos-container {
  background-image: url('../../../assets/images/Video-BG-New.jpg');
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  position: relative;
}

.main-container .recent-videos-container .recent-videos-overlay {
  background-color: #f7ecd0;
  background-image: url('../../../assets/images/Mandala-Aert.png');
  background-position: top center;
  background-repeat: no-repeat;
  opacity: 0.9;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.main-container .recent-videos-container .recent-videos-main {
  /* background-color: #F7ECD0; */
}

.main-container .recent-videos-container .recent-videos-heading {
  padding: 70px 0 40px 0;
}

.main-container .recent-videos-container .recent-videos-heading-title {
  color: #b07519;
  font-family: 'El Messiri', Sans-serif;
  font-size: 38px;
  /* font-weight: 600; */
  text-align: center;
}

.main-container .recent-videos-container .recent-videos-heading-title-divider {
  border-style: solid;
  color: #b07519;
  border-width: 2px;
  width: 175px;
  margin: 0 auto;
  font-family: 'El Messiri', Sans-serif;
}

.main-container .upcoming-events-container {
  background-image: url('../../../assets/images/Beautiful-Khana-Kaba-Picture.jpg');
  background-position: center center;
  background-size: cover;
}

.main-container .upcoming-events-main {
  background-color: #0000009e;
}
.main-container .upcoming-events-main .row {
  max-width: 1140px;
  margin: auto;
}
.main-container .upcoming-events-container .upcoming-events-heading-title {
  color: #ffffff;
  font-family: 'El Messiri', Sans-serif;
  font-size: 38px;
  /* font-weight: 600; */
  text-align: center;
}

.main-container .upcoming-events-container .upcoming-events-heading-title-divider {
  border-style: solid;
  color: #0e5472;
  border-width: 2px;
  width: 175px;
  margin: 0 auto;
  font-family: 'El Messiri', Sans-serif;
}

.main-container .upcoming-events-container .upcoming-events-heading {
  margin: 80px auto 0;
}

.main-container .upcoming-events-container .upcoming-events-lists {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.main-container .upcoming-events-container .upcoming-events-li {
  display: flex;
  justify-content: center;
}

.main-container .upcoming-events-container .upcoming-events-dates {
  background-color: #0e5472;
  color: #fff;
  padding: 12px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', Sans-serif;
}

.main-container .upcoming-events-container .upcoming-events-dates h4 {
  font-size: 24px;
  margin-bottom: 4px;
}

.main-container .upcoming-events-container .upcoming-events-dates p {
  font-size: 14px;
  margin-bottom: 0;
}

.main-container .upcoming-events-container .upcoming-events-li-info {
  font-family: 'Poppins', Sans-serif;
  background-color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0 10px;
}

.main-container .upcoming-events-container .upcoming-events-li-info p {
  font-size: 14px;
  margin-bottom: 0;
  /* padding-left: 20px; */
  font-weight: 600;
}

.main-container .upcoming-events-container .time-and-details {
  display: flex;
  gap: 10px;
}

.main-container .upcoming-events-container .time-and-details span {
  font-size: 10px;
  color: #000;
}

.main-container .upcoming-events-container .time-and-details span.details {
  color: #4b9b80;
}

.main-container .upcoming-events-container .upcoming-events-action {
  font-family: 'El Messiri', Sans-serif;
  font-size: 18px;
  font-weight: 400;
  background-color: #0e5472;
  padding: 15px 30px;
  border-radius: 4px;
  width: 190px;
  margin: 0 auto 80px;
}

.main-container .upcoming-events-container .upcoming-events-action .button-link {
  text-decoration: none;
}

.main-container
  .upcoming-events-container
  .upcoming-events-action
  .button-link
  .button-content-wrapper {
  display: flex;
  gap: 20px;
  color: white;
}

.main-container .contact-info-container {
  padding: 70px 0 0 0;
  position: relative;
  z-index: 1;
  background-image: url('../../../assets/images/texture.png');
  background-size: 16%;
}
.main-container .contact-info-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 46%;
  z-index: -1;
  background-color: #0e5472;
}
.main-container .contact-info-container .contact-info-heading-title {
  color: #b07519;
  font-family: 'El Messiri', Sans-serif;
  font-size: 38px;
  /* font-weight: 600; */
  text-align: center;
}

.main-container .contact-info-container .contact-info-heading-title-divider {
  border-style: solid;
  color: #b07519;
  border-width: 2px;
  width: 175px;
  margin: 0 auto;
  font-family: 'El Messiri', Sans-serif;
}

.main-container .contact-info-container .contact-info-row {
  max-width: 1140px;
  margin: auto;
}

.main-container .contact-info-container .contact-info-map-wrapper {
  margin: 100px 0 0px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.main-container .contact-info-container .contact-info-map-wrapper iframe {
  max-width: 100%;
  width: 100%;
  margin: 0;
  line-height: 1;
  border: none;
  height: 500px;
}

.main-container .contact-info-container .contact-info-address {
  border-bottom: 1px solid #fff;
}

.main-container .contact-info-container .contact-info-address .row .col-md-4 {
  /* padding: 0; */
}

.main-container .contact-info-container .contact-info-address .card {
  background-color: #ffffff;
  border-radius: 0px;
  height: 290px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.main-container .contact-info-container .contact-info-address .card h4 {
  color: #000;
}
.main-container .contact-info-container .contact-info-address .card.card2 h4 {
  color: #fec468;
}
.main-container .contact-info-container .contact-info-address .card h3 {
  color: #000000;
}
.main-container .contact-info-container .contact-info-address .card.card2 h3 {
  color: #fec468;
}
.main-container .contact-info-container .contact-info-address .card a,
.main-container .contact-info-container .contact-info-address .card p {
  color: #000000;
  text-align: center;
  text-decoration: none;
  margin-bottom: 1rem;
}

.main-container .contact-info-container .contact-info-address .card.card2 {
  background-color: #0e5472;
}

.main-container .contact-info-container .contact-info-address .card.card2 p {
  color: #fec468;
}

.main-container .footer-container {
  background-color: #0e5472;
  padding: 60px 0 40px 0;
}

.main-container .footer-container .img-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.main-container .footer-container .img-wrapper img {
  width: 16%;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.main-container .footer-container .social-media-link-wrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.main-container .footer-container .social-media-link-wrapper a {
  font-size: 25px;
  color: #fec468;
}

.main-container .footer-container .footer-last-title {
  font-size: 18px;
  color: #fec468;
  text-align: center;
}

/* recent video section start */
.recent-videos::before {
  background-image: url('../../../assets/images/texture.png');
  background-size: 16%;
  position: absolute;
  width: 100%;
  content: '';
  height: 100%;
  left: 0;
  z-index: -1;
}

.recent-videos .banner-title {
  text-align: center;
  padding: 75px 0;
  position: relative;
}

.recent-videos .banner-title h1 {
  color: #b07519;
  font-family: 'El Messiri', Sans-serif;
  font-size: 38px;
  font-weight: 600;
  position: relative;
}

.recent-videos .banner-title h1::before {
  width: 11%;
  content: '';
  position: absolute;
  bottom: -20px;
  height: 5px;
  background-color: #b07519;
  left: 50%;
  transform: translateX(-50%);
}

/* video section */
.recent-videos .main-video-section {
  max-width: 1140px;
  margin: auto;
  display: flex;
}

.recent-videos .main-video-section .video iframe {
  width: 100%;
}

.recent-videos .main-video-section .video {
  width: 66%;
}

.recent-videos .main-video-section .video-lists {
  width: 44%;
  height: 415px;
  background-color: #fff;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08);
}

.recent-videos .main-video-section .video-lists .title {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
}

.recent-videos .main-video-section .video-lists .title h1 {
  color: #0e5472;
  font-family: 'El Messiri', Sans-serif;
  font-size: 26px;
  margin-bottom: 0;
}

.recent-videos .main-video-section .video-lists .title p {
  color: #0e5472;
  font-family: 'El Messiri', Sans-serif;
  font-size: 16px;
  margin-bottom: 0;
}

.recent-videos {
  position: relative;
  padding-bottom: 50px;
}

.recent-videos .main-video-section .video-lists .list {
  padding: 0 16px;
  overflow: auto;
  height: 350px;
}
.recent-videos .main-video-section .video-lists .list::-webkit-scrollbar {
  display: none;
}
.recent-videos .main-video-section .video-lists .list ul {
  padding-left: 0;
}

.recent-videos .main-video-section .video-lists .list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0;
  position: relative;
}
.recent-videos .main-video-section .video-lists .list ul li:hover {
  cursor: pointer;
}

.recent-videos .main-video-section .video-lists .list ul li .image {
  width: calc(20% - 10px);
  position: relative;
}
.recent-videos .main-video-section .video-lists .list ul li .image svg {
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: #fff;
  font-size: 15px;
}
.recent-videos .main-video-section .video-lists .list ul li:hover .image svg {
  color: #1ced1c;
}
.recent-videos .main-video-section .video-lists .list ul li .image img {
  width: 100%;
}

.recent-videos .main-video-section .video-lists .list ul li h4 {
  width: calc(60% - 10px);
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: #7a7a7a;
}

.slick-slide {
  padding: 0 10px;
}

.recent-videos .main-video-section .video-lists .list ul li .time {
  width: calc(20% - 10px);
  text-align: center;
}

.recent-videos .slider-list-videos > div {
  margin-top: 20px;
}

.recent-videos .slider-list-videos {
  max-width: 1140px;
  margin: auto;
}

.recent-videos .slider-list-videos > div iframe {
  height: 200px;
  width: 100%;
}

.recent-videos .load-more {
  text-align: center;
  padding: 50px 0;
}

.recent-videos .load-more a {
  font-family: 'El Messiri', Sans-serif;
  font-size: 18px;
  font-weight: 400;
  background-color: #0e5472;
  border: none;
  padding: 15px 30px;
  color: #fff;
  text-decoration: none;
}
.recent-videos .load-more a span {
  padding-left: 15px;
}

/* about scholar css */
.img-quote-card img {
  width: 95%;
}
.img-quote-card .quotes-card {
  background-color: #0e5472;
  padding: 30px;
  width: 80%;
  margin-left: auto;
  position: absolute;
  bottom: -122px;
  right: -15px;
}
.img-quote-card .quotes-card span {
  font-size: 20px;
  color: #fff;
}
.img-quote-card .quotes-card h2 {
  color: #fff;
  font-family: 'El Messiri', Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
}
.img-quote-card .quotes-card p {
  text-align: right;
  color: #fff;
}
/* recent video section End */

/* home banner css Start */
.banner-container .banner-slider .slick-slide {
  padding: 0;
  position: relative;
}
.banner-container .banner-slider,
.banner-container .banner-slider .slick-slider {
  height: calc(100vh - 87px);
  margin: 87px auto 0;
}
.banner-container .banner-slider .slick-slider .slick-list,
.banner-container .banner-slider .slick-slider .slick-list .slick-track {
  height: 100%;
}
.banner-container .banner-slider .slick-slide .slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
  max-width: 1140px;
}

.banner-container .banner-slider .slick-slide .slide-content h2 {
  color: #ffffff;
  font-family: 'El Messiri';

  font-size: 45px;
  font-weight: 800;
  text-transform: uppercase;
  font-style: normal;
  line-height: 55px;
  margin-bottom: 24px;
}
.banner-container .banner-slider .slick-slide .slide-content p {
  color: #ffffff;
  font-family: 'Raleway', Sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  line-height: 33px;
  margin-bottom: 24px;
}
.banner-container .banner-slider .slick-slide .slide-content a {
  background: transparent;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
  padding: 12px 24px;
  display: inline-block;
}
.slick-slider .slick-prev {
  left: 0;
  z-index: 1;
}
.slick-slider .slick-next {
  right: 0;
  z-index: 1;
}
.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  content: '' !important;
  position: absolute;
  border-left: 4px solid #fff;
  border-top: 4px solid #fff;
  width: 15px;
  height: 15px;
  opacity: 1;
}
.slick-slider .slick-prev::before {
  transform: rotate(-45deg);
  left: 25px;
}
.slick-slider .slick-next::before {
  transform: rotate(135deg);
  right: 25px;
}
.slick-slider .slick-dots {
  display: none !important;
}
.mb-logo {
  display: none;
}

.weekly-lecture {
  padding: 80px 0 0;
  background-image: url('../../../assets/images/texture.png');
  background-size: 16%;
}
.weekly-lecture .lecture img {
  width: 100%;
}
.weekly-lecture .lecture > div {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.weekly-lecture .title h1 {
  color: #b07519;
  font-family: 'El Messiri', Sans-serif;
  font-size: 38px;
  text-align: center;
  position: relative;
  margin-bottom: 40px;
}
.weekly-lecture .title,
.weekly-lecture .lecture {
  max-width: 1140px;
  margin: auto;
}
.weekly-lecture .lecture {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.weekly-lecture .lecture .mixlr {
  display: flex;
  align-items: center;
}
.weekly-lecture .title h1::after {
  width: 11%;
  content: '';
  position: absolute;
  bottom: -20px;
  height: 5px;
  background-color: #b07519;
  left: 50%;
  transform: translateX(-50%);
}
.weekly-lecture .title p {
  text-align: center;
  font-size: 18px;
  display: block;
  color: #121c3b;
  font-weight: 500;
}
/* home banner css End */
@media (max-width: 991px) {
  .main-container .navbar {
    padding: 10px;
  }
  .main-container .about-container .about-left {
    margin-bottom: 35px;
  }

  /* recent video section start */
  .recent-videos .main-video-section {
    flex-wrap: wrap;
    padding: 0 10px;
  }

  .recent-videos .main-video-section .video,
  .recent-videos .main-video-section .video-lists {
    width: 100%;
  }

  .recent-videos .slider-list-videos > div iframe {
    width: 100%;
    margin-bottom: 10px;
  }
  /* recent video section End */

  /* about scholar css */
  .img-quote-card {
    padding: 0px 10px 130px;
  }
  .img-quote-card .quotes-card {
    padding: 15px;
    width: 87%;
  }
  .img-quote-card .quotes-card h2 {
    font-size: 12px;
    line-height: 20px;
  }
  .img-quote-card .quotes-card p {
    font-size: 12px;
  }
  /* about scholar end */
  .banner-container .banner-slider .slick-slide .slide-content h2 {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 18px;
  }
  .banner-container .banner-slider .slick-slide .slide-content p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .banner-container .banner-slider .slick-slide .slide-content a {
    padding: 5px 24px;
  }
  .main-container .about-container {
    padding: 0;
  }
  .recent-videos .banner-title {
    text-align: left;
    padding: 0px 12px 50px;
  }
  .recent-videos .banner-title h1::before {
    width: 40%;
    content: '';
    left: 0px;
    transform: none;
  }
  .navbar-container .menu li > div {
    display: flex;
    justify-content: space-between;
  }
  .mb-logo {
    display: block;
    cursor: pointer;
  }
  .banner-container .banner-slider,
  .banner-container .banner-slider .slick-slider {
    height: auto;
    margin: 60px auto 0;
  }
}
@media (min-width: 1921px) {
  .banner-container .banner-slider,
  .banner-container .banner-slider .slick-slider {
    height: 75vh;
  }
}
.upcoming-action img {
  height: 135px;
  transition: 1s;
  cursor: pointer;
}
.upcoming-action img:hover {
  transform: scale(1.2);
}
.audio-li {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #00000047;
  padding-bottom: 5px;
}
.audio-li .audio-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-break: break-all;
}
.audio-li .audio-div h5 {
  margin-bottom: 0;
}
.audio-li-image {
  width: 50%;
}
.audio-li-image img {
  width: 110px;
  height: 80px;
}

.audio-li audio {
  width: 291px;
  height: 35px;
}
@media (max-width: 500px) {
  .audio-li .audio-div {
    width: 100%;
  }
  .audio-li audio {
    width: 100%;
  }
}

.bounce {
  background-image: linear-gradient(#ffffffab, #ffffffab), url('../../../assets/images/texture.png');
  background-size: 20%;
  height: 50px;
  overflow: hidden;
  position: relative;
  font-weight: bold;
}

.bounce p {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  transform: translateX(50%);
  animation: bouncing-text 12s ease-in-out infinite alternate;
  color: #bc8a25;
  font-size: 16px;
  letter-spacing: 0.4px;
}

.bounce p a{
  padding-left: 5px;
}

@keyframes bouncing-text {
  0% {
      transform: translateX(50%);
  }

  100% {
      transform: translateX(-50%);
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* -webkit-box-shadow: 0 0 0 30px #202633 inset !important; */
  transition: background-color 600000s 0s, color 600000s 0s;
}
/* input[data-autocompleted] {
    background-color: #202633 !important;
} */

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

.react-loading {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

.react-loading-div {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
}

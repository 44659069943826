@import url("../home/style.css");
.shajrah-page {
  margin-top: 87px;
}
.shajrah-page .title,
.shajrah-page .tabs-wrap,
.shajrah-page .arrordians {
  max-width: 1140px;
  margin: auto;
}
.shajrah-page .title {
  padding: 40px 0 60px;
  text-align: center;
}
.shajrah-page .arrordians {
  margin-bottom: 40px;
}
.shajrah-page .arrordians .list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  background-color: #f1f1f1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  align-items: baseline;
  margin-bottom: 15px;
}
.shajrah-page .tabs-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  border: 1px solid #0e5472;
}
.shajrah-page .tabs-wrap .btn {
  width: 50%;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  color: #0e5472;
  border-radius: 0;
}
.shajrah-page .tabs-wrap .btn.active {
  background-color: #0e5472;
  color: #fff;
}

.shajrah-page .title h1 {
  color: #b07519;
  font-family: "El Messiri", Sans-serif;
  font-size: 38px;
  position: relative;
}
.shajrah-page .title h1::after {
  width: 8%;
  content: "";
  position: absolute;
  bottom: -20px;
  height: 5px;
  background-color: #b07519;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1199px) {
  .shajrah-page .title,
  .shajrah-page .arrordians {
    padding-left: 20px;
    padding-right: 20px;
  }
  .shajrah-page .tabs-wrap {
    width: calc(100% - 40px);
    margin: 0 auto 30px;
  }
}

@media (max-width: 767px) {
  .shajrah-page .tabs-wrap .btn {
    width: 100%;
  }
}
